<!-- Step 4 -->
<div class="purchase-sideways-panels">
  <div id="checkout-payment-methods">
    <h4 class="u_margin-md-bottom">Payment Method</h4>
    <form #paymentMethodsForm="ngForm" id="forms.paymentMethodsForm" role="form" name="forms.paymentMethodsForm" novalidate>
      <payment-methods-form [showInvoiceOption]="!purchase.volumePlan.isMonthly" [contactEmail]="purchase.contact.email"></payment-methods-form>
    </form>
  </div>
  @if (purchaseStepsService.currentStep === 2) {
    <purchase-summary id="checkout-purchase-summary"></purchase-summary>
  }
</div>
@if (paymentMethods.tokenError) {
  <notification-alert
    id="payment-token-error"
    type="danger"
    title="Card Processing Error"
    [message]="paymentMethods.tokenError"
    cssClass="u_margin-md-top">
  </notification-alert>
}
@if (purchase.checkoutError) {
  <notification-alert
    id="payment-checkout-error"
    type="danger"
    title="Payment Error"
    [message]="purchase.checkoutError"
    cssClass="u_margin-md-top">
  </notification-alert>
}
<div class="button-row text-right mt-5">
  <button id="backButton" type="button" aria-label="Go back to Billing Address" class="btn btn-default btn-toolbar pull-left" (click)="purchaseStepsService.setPreviousStep()">Back</button>
  @if (paymentMethods.paymentMethod === 'card') {
    <button id="payButton" type="submit" class="btn btn-primary btn-toolbar-wide" form="form.paymentMethodsForm" (click)="completeCardPayment()" tabindex="1" aria-label="Complete Payment">
      <span id="payLabel">Pay {{purchase.estimate.total / 100 | currency}} Now</span>
    </button>
  }
  @if (paymentMethods.paymentMethod === 'invoice') {
    <button id="invoiceButton" type="submit" class="btn btn-primary btn-toolbar-wide" form="form.paymentMethodsForm" (click)="completePayment()" tabindex="1" aria-label="Complete Payment">
      <span id="invoiceLabel">Invoice Me {{purchase.estimate.total / 100 | currency}} Now</span>
    </button>
  }
</div>
