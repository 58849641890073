<!-- Step 2 -->
<div class="purchase-sideways-panels">
  <div id="checkout-billing-address" class="address-editor">
    <h4 class="u_margin-md-bottom">Company Billing Details</h4>
    <form #billingAddressForm="ngForm" id="forms.billingAddressForm" role="form" name="forms.billingAddressForm" (ngSubmit)="validateAddress()" autocomplete="on" novalidate>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group" [ngClass]="{ 'has-error': (firstName.dirty || billingAddressForm.submitted) && firstName.invalid }">
            <label for="contact-firstName" class="control-label">First Name: *</label>
            <input #firstName="ngModel" id="contact-firstName" type="text" class="form-control" name="firstName" [(ngModel)]="contact.firstName" autocomplete="given-name" aria-required="true" tabindex="1" required>
            <p class="text-danger" [shown]="(firstName.dirty || billingAddressForm.submitted) && firstName.invalid">
              Choose a First Name.
            </p>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group" [ngClass]="{ 'has-error': (lastName.dirty || billingAddressForm.submitted) && lastName.invalid }">
            <label for="contact-lastName" class="control-label">Last Name: *</label>
            <input #lastName="ngModel" id="contact-lastName" type="text" class="form-control" name="lastName" [(ngModel)]="contact.lastName" autocomplete="family-name" aria-required="true" tabindex="1" required>
            <p class="text-danger" [shown]="(lastName.dirty || billingAddressForm.submitted) && lastName.invalid">
              Choose a Last Name.
            </p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group" [ngClass]="{ 'has-error': (email.dirty || billingAddressForm.submitted) && email.invalid }">
            <label for="contact-email" class="control-label">Billing Email: *</label>
            <input #email="ngModel" id="contact-email" type="email" class="form-control" name="email" [(ngModel)]="contact.email" autocomplete="email" aria-required="true" tabindex="1" required [pattern]="EMAIL_REGEX">
            <p class="text-danger" [shown]="(email.dirty || billingAddressForm.submitted) && email.invalid">
              Choose a Billing Email.
            </p>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="contact-phone" class="control-label">Phone:</label>
            <input #tel="ngModel" id="contact-phone" name="tel" type="tel" class="form-control" [(ngModel)]="contact.telephone" autocomplete="tel" tabindex="1">
          </div>
        </div>
      </div>
      <address-form [addressObject]="billingAddress"></address-form>
    </form>
  </div>
  @if (purchaseStepsService.currentStep === 1) {
    <purchase-summary id="checkout-estimate-summary"></purchase-summary>
  }
</div>
@if (billingAddress.validationError) {
  <notification-alert
    id="billing-address-error"
    type="danger"
    title="Address Validation Error"
    [message]="billingAddress.validationError"
    cssClass="u_margin-md-top">
    <suggest-general-delivery [addressObject]="billingAddress"></suggest-general-delivery>
  </notification-alert>
}
<div class="button-row text-right mt-5">
  <button id="backButton" type="button" class="btn btn-default btn-toolbar pull-left" (click)="purchaseStepsService.setPreviousStep()" aria-label="Go back to Subscription Details" tabindex="2">Back</button>
  <button id="continueButton" aria-label="Continue to Payment Method" type="submit" form="forms.billingAddressForm" class="btn btn-primary btn-toolbar" tabindex="1">Continue</button>
</div>
