import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { UIRouterModule } from '@uirouter/angular';
import { PopoverModule } from 'ngx-bootstrap/popover';

import { ComponentsModule } from '../components/components.module';
import { SharedModule } from '../shared/shared.module';

import { CardDescriptionPipe } from './pipes/card-description.pipe';
import { TaxExemptionComponent } from './components/tax-exemption/tax-exemption.component';
import { PurchaseFooterComponent } from './components/purchase-footer/purchase-footer.component';
import { PurchaseSummaryComponent } from './components/purchase-summary/purchase-summary.component';
import { CheckoutSuccessComponent } from './components/checkout-success/checkout-success.component';
import { AddressFormComponent } from './components/address-form/address-form.component';
import { BillingAddressComponent } from './components/billing-address/billing-address.component';
import { ProvinceValidatorDirective } from './directives/province-validator.directive';
import { PaymentMethodsComponent } from './components/payment-methods/payment-methods.component';
import { PaymentMethodsFormComponent } from './components/payment-methods-form/payment-methods-form.component';
import { CreditCardFormComponent } from './components/credit-card-form/credit-card-form.component';
import { PurchaseComponent } from './components/purchase/purchase.component';
import { routes } from './purchase.route';
import { UpdateSubscriptionComponent } from './components/update-subscription/update-subscription.component';
import { UpdateSubscriptionSuccessComponent } from './components/update-subscription-success/update-subscription-success.component';
import { ViewportComponent } from './viewport/viewport.component';
import { PlanTierPickerComponent } from './components/plan-tier-picker/plan-tier-picker.component';
import { SelectPlanComponent } from './components/select-plan/select-plan.component';
import { UpdatePlanComponent } from './components/update-plan/update-plan.component';
import { ChangePlanComponent } from './components/change-plan/change-plan.component';
import { PendingChangeAlertComponent } from './components/pending-change-alert/pending-change-alert.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { NotificationAlertComponent } from '../components/notification-alert/notification-alert.component';

@NgModule({
  declarations: [
    CardDescriptionPipe,
    TaxExemptionComponent,
    PurchaseFooterComponent,
    PurchaseSummaryComponent,
    CheckoutSuccessComponent,
    AddressFormComponent,
    BillingAddressComponent,
    ProvinceValidatorDirective,
    PaymentMethodsComponent,
    PaymentMethodsFormComponent,
    CreditCardFormComponent,
    PurchaseComponent,
    UpdateSubscriptionComponent,
    UpdateSubscriptionSuccessComponent,
    ViewportComponent,
    PlanTierPickerComponent,
    SelectPlanComponent,
    UpdatePlanComponent,
    ChangePlanComponent,
    PendingChangeAlertComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    UIRouterModule.forChild(routes),
    ComponentsModule,
    SharedModule,
    PopoverModule.forRoot(),
    MatCheckboxModule,
    MatRadioModule,
    NotificationAlertComponent
  ],
  exports: [
    CardDescriptionPipe,
    TaxExemptionComponent,
    PurchaseFooterComponent,
    CreditCardFormComponent,
    PaymentMethodsFormComponent,
    PendingChangeAlertComponent
  ]
})
export class PurchaseModule {
}
