<div>
  <div class="row my-5">
    <div class="col-xs-12 col-sm-2 pb-5 pb-md-0">
      <div class="rise-logo"><img src="https://s3.amazonaws.com/Rise-Images/Website/rise-logo.svg"></div>
    </div>
    <div class="col-xs-12 col-sm-10">
      <div class="button-row text-right my-0">
        <share-url-button></share-url-button>
        <button id="downloadButton" type="button" class="btn btn-default btn-toolbar" (click)="invoiceService.downloadInvoice(invoiceService.invoice.id)">
          Download
        </button>
        <button id="payNowButton" type="button" class="btn btn-primary btn-toolbar" (click)="showPaymentForm = true" [disabled]="showPaymentForm" [hidden]="invoiceService.invoice.status === 'paid'">
          Pay Now
        </button>
      </div>
    </div>
  </div>
  <div rvSpinner="invoice-loader" [rvShowSpinner]="invoiceService.loading"></div>

  @if (invoiceService.apiError) {
    <notification-alert
      id="invoice-error"
      type="danger"
      [title]="invoiceService.apiError"
      message="If you need help please email us at support@risevision.com">
    </notification-alert>
  }

  <div class="border-container u_margin-md-top u_margin-md-bottom" [shown]="showPaymentForm && invoiceService.invoice.status !== 'paid'">
    <div class="panel-body">
      <form #paymentMethodsForm="ngForm" id="forms.paymentMethodsForm" role="form" name="forms.paymentMethodsForm" novalidate>
        <credit-card-form></credit-card-form>
      </form>
      <div class="text-right mt-5">
        <button id="cancelButton" type="button" aria-label="Cancel" class="btn btn-default btn-toolbar pull-left" (click)="showPaymentForm = false">Cancel</button>
        <button id="payButton" type="submit" class="btn btn-primary btn-toolbar-wide" form="forms.paymentMethodsForm" (click)="completeCardPayment()" tabindex="1" aria-label="Complete Payment">
          <span id="payLabel">Pay {{invoiceService.invoice.amount_due / 100 | currency}} Now</span>
        </button>
      </div>
    </div>
  </div>
  <div class="border-container u_margin-md-top u_margin-md-bottom">
    <div class="panel-body">
      <div class="pb-5 mb-5 border-bottom">
        <div class="row">
          <div class="col-sm-12 col-md-6 pb-4 pb-lg-0">
            <div class="app-header mb-3">
              <!-- App Title -->
              <h1 class="app-header-title">Invoice #{{invoiceService.invoice.id}}</h1>
            </div>
            <label>Billed To:</label><br>
            <span [shown]="invoiceService.invoice.billing_address?.first_name || invoiceService.invoice.billing_address?.last_name">
              {{invoiceService.invoice.billing_address?.first_name}} {{invoiceService.invoice.billing_address?.last_name}}<br>
            </span>
            {{invoiceService.invoice.billing_address?.company}}<br>
            {{invoiceService.invoice.billing_address?.line1}}<br>
            <span [shown]="invoiceService.invoice.billing_address?.line2">
              {{invoiceService.invoice.billing_address?.line2}}
              <br>
            </span>
            {{invoiceService.invoice.billing_address?.city}}, <span [shown]="invoiceService.invoice.billing_address?.state">{{invoiceService.invoice.billing_address?.state}},</span> {{invoiceService.invoice.billing_address?.zip}}<br>
            {{invoiceService.invoice.billing_address?.country | countryName}}<br>
            <span [shown]="invoiceService.invoice.billing_address?.email">
              {{invoiceService.invoice.billing_address?.email}}<br>
            </span>
            <span [shown]="invoiceService.invoice.billing_address?.phone">
              {{invoiceService.invoice.billing_address?.phone}}<br>
            </span>
          </div>
          <div class="col-sm-12 col-md-6">
            <div class="app-header mb-3" [shown]="invoiceService.invoice.status === 'paid'">
              <!-- App Title -->
              <h1 class="app-header-title text-success">Paid</h1>
            </div>
            <div class="row">
              <div class="col-md-4">
                <label class="mb-0">Invoice Date:</label>
              </div>
              <div class="col-md-8" [shown]="invoiceService.invoice.date">
                {{invoiceService.invoice.date * 1000 | date:'d-MMM-yyyy'}}
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <label class="mb-0">Invoice Amount:</label>
              </div>
              <div class="col-md-8" [shown]="invoiceService.invoice">
                {{invoiceService.invoice.total / 100 | currency}} {{invoiceService.invoice.currency_code}}
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <label class="mb-0">Customer ID:</label>
              </div>
              <div class="col-md-8">
                {{invoiceService.invoice.customer_id}}
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <label class="mb-0">Payment Terms:</label>
              </div>
              <div class="col-md-8">
                <span [shown]="invoiceService.invoice.net_term_days > 0">
                  Net {{invoiceService.invoice.net_term_days}}
                </span>
                <span [shown]="invoiceService.invoice.net_term_days === 0">
                  Due Upon Receipt
                </span>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <label class="mb-0">Due Date:</label>
              </div>
              <div class="col-md-8" [shown]="invoiceService.invoice.due_date">
                {{invoiceService.invoice.due_date * 1000 | date:'d-MMM-yyyy'}}
              </div>
            </div>
            @if (invoiceService.invoice.line_items && invoiceService.invoice.line_items[0]) {
              <div class="row">
                <div class="col-md-4">
                  <label class="mb-0">Billing Period:</label>
                </div>
                <div class="col-md-8" [shown]="invoiceService.invoice.line_items[0]">
                  {{invoiceService.invoice.line_items[0].date_from * 1000 | date:'d-MMM-yyyy'}} — {{invoiceService.invoice.line_items[0].date_to * 1000 | date:'d-MMM-yyyy'}}
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <label class="mb-0">Next Billing Date:</label>
                </div>
                <div class="col-md-8" [shown]="invoiceService.invoice.line_items[0]">
                  {{invoiceService.invoice.line_items[0].date_to * 1000 | date:'d-MMM-yyyy'}}
                </div>
              </div>
            }
            <edit-po-number [item]="invoiceService.invoice" [updateFunction]="updateInvoice"></edit-po-number>
          </div>
        </div>
      </div>
      <div class="pb-5 mb-5 border-bottom" [shown]="invoiceService.invoice.line_items">
        @for (line_item of invoiceService.invoice.line_items; track line_item) {
          <div class="text-right">
            <label class="mb-0 pull-left">{{line_item.description}}</label>
            {{line_item.amount / 100 | currency}}
          </div>
        }
      </div>
      <div [shown]="invoiceService.invoice">
        <div class="row">
          <div class="col-md-offset-8 col-md-4">
            <div class="pb-3 mb-3 border-bottom" [hidden]="invoiceService.invoice.sub_total === invoiceService.invoice.total">
              <div class="text-right">
                <span class="pull-left">Sub Total:</span>
                <span>
                  {{invoiceService.invoice.sub_total / 100 | currency}}
                </span>
              </div>
              @for (discount of invoiceService.invoice.discounts; track discount) {
                <div class="text-right mt-3">
                  <span class="pull-left">{{discount.description}}:</span>
                  <span>
                    -{{discount.amount / 100 | currency}}
                  </span>
                </div>
              }
              @for (tax of invoiceService.invoice.taxes; track tax) {
                <div class="text-right mt-3">
                  <span class="pull-left">{{tax.description}}:</span>
                  <span>
                    {{tax.amount / 100 | currency}}
                  </span>
                </div>
              }
            </div>
            <div class="text-right">
              <label class="mb-0 pull-left">Total:</label>
              <label class="mb-0">
                {{invoiceService.invoice.total / 100 | currency}}
              </label>
            </div>
            <div class="text-right mt-3" [shown]="invoiceService.invoice.amount_paid">
              <span class="mb-0 pull-left">Payment:</span>
              <span class="mb-0">
                -{{invoiceService.invoice.amount_paid / 100 | currency}}
              </span>
            </div>
            <div class="text-right mt-3" [shown]="invoiceService.invoice.credits_applied">
              <span class="mb-0 pull-left">Credits:</span>
              <span class="mb-0">
                -{{invoiceService.invoice.credits_applied / 100 | currency}}
              </span>
            </div>
            <div class="text-right mt-3" [shown]="invoiceService.invoice.amount_adjusted">
              <span class="mb-0 pull-left">Adjustments:</span>
              <span class="mb-0">
                -{{invoiceService.invoice.amount_adjusted / 100 | currency}}
              </span>
            </div>
            <div class="text-right mt-3 border-top">
              <h4>
                <span class="pull-left">Amount Due:</span>
                {{invoiceService.invoice.amount_due / 100 | currency}}
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <purchase-footer></purchase-footer>
</div><!--container-->
