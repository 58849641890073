<div>
  <div class="app-header u_padding-20-vertical mb-5 border-bottom">
    <!-- App Title -->
    <h1 class="app-header-title">Add Payment Method</h1>
  </div>

  <div rvSpinner="payment-source-loader" [rvShowSpinner]="subscriptionService.loading || addPaymentSourceService.loading"></div>

  @if (subscriptionService.apiError) {
    <notification-alert
      id="subscription-error"
      type="danger"
      [title]="subscriptionService.apiError"
      message=""
      cssClass="u_margin-md-top">
    </notification-alert>
  }

  @if (addPaymentSourceService.apiError) {
    <notification-alert
      id="payment-source-error"
      type="danger"
      [title]="addPaymentSourceService.apiError"
      message="If you need help please email us at support@risevision.com"
      cssClass="u_margin-md-top">
    </notification-alert>
  }

  <div class="row">
    <div class="col-md-8 col-md-offset-2">
      <h4 class="u_margin-md-bottom">Payment Method</h4>

      <div class="border-container p-4">
        <form #paymentMethodsForm="ngForm" id="forms.paymentMethodsForm" role="form" name="forms.paymentMethodsForm" novalidate>
          <payment-methods-form [showInvoiceOption]="!subscriptionService.loading && subscriptionService.getItemSubscription().billing_period_unit !== 'month' && !subscriptionService.isInvoiced()" [contactEmail]="contactEmail"></payment-methods-form>
        </form>
      </div>

      <div class="row u_margin-md-top mb-5">
        <div class="col-md-4 mb-2">
          <a id="backButton" type="button" aria-label="Go back" class="btn btn-default btn-hg btn-block" uiSref="apps.billing.subscription" [uiParams]="({subscriptionId: subscriptionService.getItemSubscription().id})">Cancel</a>
        </div>
        <div class="col-md-8">
          <button id="addButton" type="submit" class="btn btn-primary btn-hg btn-block" form="forms.paymentMethodsForm" (click)="addPaymentMethod(subscriptionService.getItemSubscription().id)" tabindex="1" aria-label="Add Payment Method">
            Add Payment Method
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="mt-5 border-top">
    <purchase-footer></purchase-footer>
  </div>

</div><!--container-->
